






































































































































































import useParticipant from "@/use/participant";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAuthPermission from "@/use/authPermissions";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    MParticipantListItem: () =>
      import("@/components/molecules/participant/m-participant-list-item.vue"),
    MCertificatesPrint: () =>
      import("@/components/molecules/massPrinting/m-certificates-print.vue"),
    MBadgesPrint: () =>
      import("@/components/molecules/massPrinting/m-badges-print.vue"),
    MPlatesPrint: () =>
      import("@/components/molecules/massPrinting/m-plates-print.vue"),
  },
  props: {
    status: {
      type: String,
      required: true,
      default: "",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root }) {
    const {
      getParticipantStatusName,
      getParticipantStatusColor,
    } = useParticipant({ root });

    const { getLanguageVersion, lettersItems } = useMisc({ root });

    const state = reactive({
      singleSelect: false,
      selected: [],
      expanded: [],
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        {
          value: "quantity",
          text: root.$tc("layout.misc.quantity"),
          width: "30px",
        },
        { value: "lastName", text: root.$tc("layout.misc.lastName") },
        {
          value: "firstName",
          text: root.$tc("layout.misc.firstName"),
          sortable: true,
        },
        {
          value: "state",
          text: root.$tc("layout.misc.state"),
          sortable: false,
        },
        {
          value: "group",
          text: root.$tc("layout.misc.groups"),
          sortable: false,
        },
        { value: "icons", text: "", sortable: false, align: "right", width: 1 },
        { text: "", value: "data-table-expand" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["lastName"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
      alerts: [],
      participantList: [] as any,
      currency: "",
      specializations: [],
      letterSort: "",
      certificatesDialog: false,
      badgesDialog: false,
      platesDialog: false,
    });

    const model = reactive({
      quantity: [],
    });

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.specializations = specializations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.specializations = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSpecialization);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            state: props.status,
            type: undefined,
            search: state.search || undefined,
            lastName: state.letterSort || undefined,
          },
        })
        .then(({ data: { participants, total } }) => {
          state.empty = false;
          state.items = participants.map((p: any) => ({
            ...p,
            quantity: 1,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
          state.selected = [];
        });
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);

    watch(() => state.options, fetchData, { deep: true });
    watch(() => props.status, fetchData, { deep: true });
    watch(() => state.letterSort, fetchData, { deep: true });

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const { participantsManagement } = useAuthPermission({
      root,
    });

    const selectedParticipants = computed(() => {
      if (state.selected && state.selected.length) {
        const participants = state.selected.map((el: any) => ({
          id: el.id,
          quantity: el.quantity,
          firstName: el.firstName,
          lastName: el.lastName,
          gender: el.gender,
          degree: el.degree,
          code: el.code,
          institutionName: el.institutionName,
          image: el.image,
          participantGroups: el.participantGroups,
          licenseNumber: el.licenseNumber,
          educationalPoints: el.educationalPoints,
        }));
        return participants;
      } else return [];
    });

    return {
      state,
      model,
      getParticipantStatusName,
      getParticipantStatusColor,
      getLanguageVersion,
      lettersItems,
      participantsManagement,
      selectedParticipants,
    };
  },
});
