var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"o-participant-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] },"single-select":_vm.state.singleSelect,"show-select":"","single-expand":"","show-expand":"","expanded":_vm.state.expanded},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","min":"1"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.firstName",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.firstName))]}},{key:"item.lastName",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.clone)?_c('span',{staticClass:"mr-2"},[_c('v-chip',{attrs:{"color":"red","outlined":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$t('layout.misc.clone')))])],1):_vm._e(),_c('span',[_vm._v(_vm._s(item.lastName))])]}},{key:"item.group",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.participantGroups)?_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.participantGroups),function(group){return _c('v-chip',{key:group.id,attrs:{"small":""}},[_vm._v(_vm._s(group.name))])}),1):_vm._e()]}},{key:"item.state",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getParticipantStatusColor(item.state)}},[_vm._v(_vm._s(_vm.getParticipantStatusName(item.state)))])]}},{key:"item.icons",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.participantsManagement)?[(item.invoices && item.invoices[0] && item.invoices[0].invoiceId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v("mdi-receipt-text-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.invoiceNo'))+" "+_vm._s(item.invoices[0].name))])]):_vm._e(),(item.paymentStatus === 'PAID')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.list.allPaid')))])]):_vm._e(),(item.paymentStatus === 'PARTIAL_PAID')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.list.partialPayment')))])]):_vm._e(),(item.paymentStatus === 'OVER_PAID')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.list.excessPayment')))])]):_vm._e(),(item.paymentStatus === 'NOT_PAID')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd-off")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.company.list.noDeposit')))])]):_vm._e()]:_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.createdBy === 'admin')?_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey","size":"24"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v("S")])]):_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey","size":"24"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v("F")])])]}}],null,true)},[(item.createdBy === 'admin')?_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.list.systemRegistration')))]):_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.list.registerViaForm')))]),(item.ipAddress)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('layout.misc.ipAddress'))+": "+_vm._s(item.ipAddress))]):_vm._e()])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.type === 'lecturer')?_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey","size":"24"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v("W")])]):_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey","size":"24"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text"},[_vm._v("U")])])]}}],null,true)},[(item.type === 'lecturer')?_c('span',[_vm._v(_vm._s(_vm.$t('event.participant.editInfo.lecturer')))]):_c('span',[_vm._v(_vm._s(_vm.$t('participant.status.participant')))])]),(item.wasPresent || item.present || item.presenceLists.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.wasPresent')))])]):_vm._e(),(item.nfcTag)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v("mdi-nfc-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.nfcAssign.cardAssign')))])]):_vm._e(),(item.editedParticipant)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-edit-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.editRequest')))])]):_vm._e(),(item.surveys && item.surveys.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v("mdi-account-question-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.hasSurveys'))+": "+_vm._s(item.surveys.length))])]):_vm._e(),(item.accommodation)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v("mdi-bed-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.hasAccommodation')))])]):_vm._e(),(item.services && item.services.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.participant.hasServices'))+": "+_vm._s(item.services.length))])]):_vm._e()],2)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('m-participant-list-item',{key:item.id,attrs:{"item":item,"headersLength":headers.length,"currency":_vm.state.currency,"specializations":_vm.state.specializations}})]}},{key:"footer.prepend",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"solo":"","dense":"","flat":"","background-color":"grey lighten-4","hide-details":"","items":_vm.lettersItems,"clearable":"","label":_vm.$t('layout.misc.sortByLetter')},model:{value:(_vm.state.letterSort),callback:function ($$v) {_vm.$set(_vm.state, "letterSort", $$v)},expression:"state.letterSort"}})],1)]},proxy:true}]),model:{value:(_vm.state.selected),callback:function ($$v) {_vm.$set(_vm.state, "selected", $$v)},expression:"state.selected"}}),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","depressed":"","color":"primary","disabled":!_vm.selectedParticipants.length}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('layout.misc.printCertificates')))])]}}]),model:{value:(_vm.state.certificatesDialog),callback:function ($$v) {_vm.$set(_vm.state, "certificatesDialog", $$v)},expression:"state.certificatesDialog"}},[_c('m-certificates-print',{attrs:{"participants":_vm.selectedParticipants,"status":_vm.state.certificatesDialog},on:{"close":function($event){_vm.state.certificatesDialog = false}}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","depressed":"","color":"primary","disabled":!_vm.selectedParticipants.length}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('layout.misc.printBadges')))])]}}]),model:{value:(_vm.state.badgesDialog),callback:function ($$v) {_vm.$set(_vm.state, "badgesDialog", $$v)},expression:"state.badgesDialog"}},[_c('m-badges-print',{attrs:{"participants":_vm.selectedParticipants,"status":_vm.state.badgesDialog},on:{"close":function($event){_vm.state.badgesDialog = false}}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","depressed":"","color":"primary","disabled":!_vm.selectedParticipants.length}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('layout.misc.printPlates')))])]}}]),model:{value:(_vm.state.platesDialog),callback:function ($$v) {_vm.$set(_vm.state, "platesDialog", $$v)},expression:"state.platesDialog"}},[_c('m-plates-print',{attrs:{"participants":_vm.selectedParticipants,"status":_vm.state.platesDialog},on:{"close":function($event){_vm.state.platesDialog = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }